.events-section {
  color: var(--text-color);
  padding: 4rem 2rem;
  position: relative;
}

.event-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.event-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: var(--text-color);
    width: 30%;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.event-button:hover {
    background-color: rgba(218, 116, 53, 0.42);
}

.event-button img {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.event-button p {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}


.events-news-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.news-widget {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.news-section {
  flex: 1;
  min-width: 300px;
  background-color: var(--background-color);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.7);
}

.news-section h3 {
  color: var(--secondary-color);
  margin-bottom: 15px;
  font-family: 'Orbitron', sans-serif;
}

.news-section ul {
  list-style-type: none;
  padding: 0;
}

.news-section li {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--text-color-light);
}

.news-section li:last-child {
  border-bottom: none;
}

.news-section a {
  color: var(--text-color);
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.news-section a:hover,
.news-section a:focus {
  color: var(--text-color-light);
}

.news-date {
  font-size: 0.8em;
  color: var(--text-color-light);
  margin-top: 5px;
}

.error-message {
  color: #ff6b6b;
  font-weight: 600;
}

/* Existing styles for event cards and modal */

@media (max-width: 768px) {
  .events-section {
    padding: 2rem 1rem;
  }

  .news-widget {
    flex-direction: column;
  }

  .news-section {
    min-width: 100%;
  }

  .event-card {
    width: calc(50% - 1rem);
  }
}

@media (max-width: 480px) {
  .event-card {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .event-button p {
    font-size: 14px;
      color: #a3c6c4;
      text-align: center;
  }
}
