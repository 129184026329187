.businesses-section {
    color: var(--text-color);
    padding: 4rem 2rem;
    position: relative;
}

.business-link:hover {
    color: var(--primary-color);
}

.sports-thumbnail {
    height: 400px; /* Adjust the height as needed */
}

.businesses-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    opacity: 0.5;
    z-index: -1;
}

.business-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 2rem;
    margin-top: 2rem;
}

.business-card {
    background-color: var(--background-color);
    border-radius: 8px;
    padding: 1rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.7);
}

.business-card:hover,
.business-card:focus-within {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(209, 232, 226, 0.2);
}

.business-type {
    font-style: italic;
    color: var(--text-color-light);
    margin-bottom: 0.5rem;
}

.business-description {
    color: var(--text-color);
}

.search-input {
    width: 100%;
    max-width: 300px;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: none;
    border-radius: 4px;
    background-color: rgba(209, 232, 226, 0.9);
    color: var(--primary-color);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.7);
}

.search-input:focus {
    outline: 2px solid var(--secondary-color);
    box-shadow: 0 0 5px rgba(209, 232, 226, 0.5);
}

@media (max-width: 768px) {
    .businesses-section {
        padding: 2rem 1rem;
    }

    .business-grid {
        grid-template-columns: 1fr;
    }
}