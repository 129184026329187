.home-section {
    position: relative;
    height: 100vh;
    overflow: hidden;
    background-color: #000;
}

.video-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
    z-index: 4;
}

.video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-background {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.3);
}

.welcome-text {
    font-family: 'Orbitron', sans-serif;
    font-size: clamp(2rem, 5vw, 3.5rem);
    color: var(--text-color);
    text-align: center;
    margin-top: 5px; /* Gap between header and text */
    padding: 0 20px; /* Ensure text doesn't touch screen edges on small devices */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    bottom: 50%;
}

.video-controls {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 3;
}

.video-controls button {
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    padding: 7px 9px;
    margin-left: 10px;
    cursor: pointer;
    transition: background-color 0.5s ease;
    font-family: 'Orbitron', sans-serif;
    font-size: 0.7rem;
    border-radius: 5px;
}

.video-controls button:hover {
    background-color: rgba(55, 198, 255, 0.7);
}

@media (max-width: 768px) {
    .welcome-text {
        font-size: clamp(1.5rem, 4vw, 2.5rem);
    }

    .video-controls {
        bottom: 10px;
        right: 10px;
    }

    .video-controls button {
        padding: 5px 8px;
        font-size: 0.6rem;
    }
}