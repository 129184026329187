.footer {
    background: linear-gradient(to top, rgba(34, 139, 34, 1), rgba(0, 100, 0, 1));
    color: var(--text-color);
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transform: translateY(100%);
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem
}

.copyright {
    text-align: center;
    width: 100%;
    margin: 0.5rem 0;
    padding: 0;
    font-size: 1rem; /* Adjust as needed */
    font-weight: bold;
}

.footer.visible {
    transform: translateY(0);
    opacity: 1;
}

.footer p {
    margin: 0.5rem 0;
}

.social-links {
    margin-top: 0.5rem;
}

.social-links {
    display: flex; /* Ensures the child elements are laid out in a row */
    justify-content: center; /* Centers the items within the container */
    margin-top: 0.5rem;
}

.social-links a {
    color: var(--text-color-light);
    margin: 0 0.5rem; /* Maintains horizontal spacing between links */
    text-decoration: none;
    transition: color 0.3s ease;
    display: inline-flex; /* Use inline-flex to maintain the flex properties on individual links without expanding */
    justify-content: center;
}


.social-icon {
    width: 24px;
    height: 24px;
    fill: var(--text-color-light);
    transition: fill 0.3s ease;
}

.social-icon:hover,
.social-icon:focus {
    fill: var(--backstop-button-color);
}

@media (max-width: 768px) {
    .footer {
        padding: 0.5rem;
    }
}

.footer {
    background-image: linear-gradient(
            to bottom,
            rgba(65,152,10, 0.3) 25%,
            rgba(92, 47, 1, 0.8) 85%
    );
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.8), inset 0 1px 0 rgba(255, 255, 255, 0.8);
}

.social-links a:focus {
    outline: 2px solid var(--text-color-light);
    outline-offset: 2px;
}