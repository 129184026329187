.contact-section {
    color: var(--text-color);
    padding: 4rem 2rem;
    position: relative;
}

.contact-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    opacity: 0.5;
    z-index: -1;
}

.contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin: 2rem auto 0;
}

.form-input,
.form-textarea {
    margin-bottom: 1rem;
    padding: 0.5rem;
    border: 1px solid var(--text-color-light);
    border-radius: 4px;
    background-color: rgba(244, 165, 90, 0.5);
    color: var(--text-color);
    font-weight: 500;
    font-size: medium;
    box-shadow: 5px 5px 5px rgb(243, 231, 231, 0.5);
}

.form-input::placeholder,
.form-textarea::placeholder {
    color: rgba(39, 39, 39, 0.6);
}

.form-textarea {
    resize: vertical;
    min-height: 150px;
}

.submit-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    background-color: var(--background-color);
    color: var(--text-color);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: var(--background-color);
}

@media (max-width: 768px) {
    .contact-section {
        padding: 2rem 1rem;
    }
}

.contact-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    opacity: 0.5;
    z-index: -1;
}

.contact-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    margin: 2rem auto 0;
}

.form-input,
.form-textarea {
    margin-bottom: 1rem;
    padding: 0.5rem;
    border: 1px solid var(--text-color-light);
    border-radius: 4px;
    background-color: rgba(244, 165, 90, 0.5);
    color: var(--text-color);
    font-weight: 500;
    font-size: medium;
    box-shadow: 5px 5px 5px rgb(243, 231, 231, 0.5);
}

.form-input::placeholder,
.form-textarea::placeholder {
    color: rgba(39, 39, 39, 0.6);
}

.form-textarea {
    resize: vertical;
    min-height: 150px;
}

.submit-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    background-color: var(--background-color);
    color: var(--text-color);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: var(--background-color);
}

@media (max-width: 768px) {
    .contact-section {
        padding: 2rem 1rem;
    }
}