.outdoors-section {
    color: var(--text-color);
    padding: 4rem 2rem;
    position: relative;
}

.weather-widget {
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 2rem;
    width: 100%;
    max-width: 1080px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.7);
    background-color: var(--background-color);
}

.activity-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
}

.activity-item {
    background-color: var(--background-color);
    border-radius: 8px;
    padding: 1rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.7);
    text-align: center;
}

.activity-item:hover,
.activity-item:focus-within {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(209, 232, 226, 0.5);
}

.activity-item a {
    color: var(--secondary-color);
    text-decoration: none;
    transition: color 0.3s ease;
    font-weight: 600;
    display: block;
}

.activity-item a:hover,
.activity-item a:focus {
    color: var(--text-color-light);
}

.activity-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
    .outdoors-section {
        padding: 2rem 1rem;
    }

    .activity-grid {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
}

@media (max-width: 480px) {
    .activity-grid {
        grid-template-columns: 1fr;
    }
}