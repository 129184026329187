.education-sports-section {
    color: var(--text-color);
    padding: 4rem 2rem;
    position: relative;
}

.education-sports-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    opacity: 0.5;
    z-index: -1;
}

.subsection {
    margin-top: 2rem;
}

.thumbnail-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    justify-content: center;
    max-width: 800px;
    margin: 0 auto;
}

.thumbnail {
    background-color: var(--background-color);
    border-radius: 8px;
    padding: 1rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center;
    text-decoration: none;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.7);
    aspect-ratio: 1 / 1;
}

.thumbnail:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(209, 232, 226, 0.5);
}

.thumbnail img {
    width: 100%;
    height: 70%;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 0.5rem;
}

.thumbnail p {
    margin: 0;
    font-weight: 600;
    font-size: 1rem;
}

@media (max-width: 768px) {
    .education-sports-section {
        padding: 2rem 1rem;
    }

    .thumbnail-grid {
        grid-template-columns: 1fr;
    }
}