.chatbot-container {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.chatbot-container:hover {
    background-color: rgba(209, 232, 226, 0.9);
}

.chatbot-container.open {
    height: 600px;
}

.chatbot-container.mobile {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.chatbot-container.mobile.open {
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    border-radius: 0;
}

.chatbot-header {
    background-color: var(--primary-color);
    color: var(--text-color);
    padding: 10px;
    font-weight: bold;
    cursor: pointer;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: 10%;
    transition: background-color 0.3s ease;
}

.chatbot-header:hover{
    background-color: var(--backstop-button-color);
}

.chatbot-icon {
    font-size: 22px;
    margin-right: 10px;
}

.chatbot-body {
    height: 450px;
    overflow-y: auto;
    padding: 10px;
}

.message {
    margin-bottom: 10px;
    padding: 8px 12px;
    border-radius: 10px;
    max-width: 80%;
    font-size: 14px;
}

.message.user {
    background-color: var(--secondary-color);
    color: var(--primary-color);
    margin-left: auto;
}

.message.bot {
    background-color: var(--primary-color);
    color: var(--text-color);
}

.chatbot-input {
    display: flex;
    padding: 10px;
}

.chatbot-input input {
    flex-grow: 1;
    padding: 5px;
    border: 1px solid var(--text-color-light);
    border-radius: 5px 0 0 5px;
    font-size: 14px;
}

.chatbot-input input:focus {
    outline: none;
    border-color: var(--secondary-color);
}

.chatbot-input button {
    padding: 5px 10px;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    border: 1px solid var(--secondary-color);
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.chatbot-input button:hover,
.chatbot-input button:focus {
    background-color: var(--primary-color);
    color: var(--text-color);
}

@media (max-width: 768px) {
    .chatbot-container {
        bottom: 10px;
        left: 10px;
    }
}

@media (min-width: 769px) {
    .chatbot-header .chatbot-icon {
        display: none;
    }
}

@media (max-width: 768px) {
    .chatbot-header span:not(.chatbot-icon) {
        display: none;
    }
}